// Подключаем библиотеку Axios (убедитесь, что она установлена: npm install axios)
import axios from 'axios'
import { LocalStorageService } from '@/5_shared/local-storage'
import { NotificationService } from '@/7_services/notification-service'
import { isDevelopment } from '@/5_shared/config'
import i18next from '@/lang'
import { UserService } from '@/7_services/user'

type Methods = 'GET' | 'POST' | 'PUT' | 'DELETE'

type Request = {
  id?: number
  width?: number
  height?: number
  denomination?: number
  currency_id?: number
  game_id?: number
  time?: number
  cmd: string
  command_mode?: number
  endpoint?: string
}

class Api {
  baseUrl: string
  token: string | null

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
    const token: { jwt: string } | null = LocalStorageService.getItem('jwt')
    this.token = token?.jwt || null // JWT токен для авторизации
  }

  setToken(token: string) {
    this.token = token

    LocalStorageService.setItem('jwt', { jwt: token })
  }

  clearToken() {
    this.token = null
  }

  async request(method: Methods, endpoint?: string, data?: unknown) {
    const config = {
      method,
      url: `${this.baseUrl}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      data,
    }

    try {
      const response = await axios(config)

      if (response.data.success) {
        switch (response.data.success) {
          case 1: // success
            return response.data

          case -21: // token is expired
            // NotificationService.error(i18next.t('notifications.token.error'))
            UserService.logout()
            return
          case -22: // token is invalid
          case -50: // balance error
            UserService.logout()
            return

          case -12102:
            NotificationService.error(i18next.t('notifications.wallet.error'))
            return
          case -35:
            NotificationService.error(i18next.t('notifications.withdraw.error'))
            return

          default:
            throw response.data
        }
      } else if (response.data && response.status === 200) {
        return response.data
      }
      throw response
    } catch (error: unknown) {
      // Обработка ошибок (вывод в консоль или дополнительная обработка)
      console.error('API request error:', error)
      throw error
    }
  }

  get(endpoint: string) {
    return this.request('GET', endpoint)
  }

  post(service: Request, data?: object, isPublic?: boolean) {
    const payload = {
      cmd: service.cmd,
      command_mode: service.command_mode,
      ...data,
    }
    if (!isPublic) {
      payload.jwt = this.token
    }
    return this.request('POST', service.endpoint, {
      data: JSON.stringify(payload),
    })
  }

  put(endpoint: string, data: unknown) {
    return this.request('PUT', endpoint, data)
  }

  delete(endpoint: string) {
    return this.request('DELETE', endpoint)
  }
}

const api = new Api(isDevelopment() ? '/api' : '/middleware.php')

export { api as Api }
