export const currencyList = [
  {
    CurrencyID: 162,
    ShortName: 'UST',
    Name: 'USDT TRC 2.0 token',
  },
  {
    CurrencyID: 167,
    ShortName: 'UBC',
    Name: 'Micro BTC uBTC',
  },
  {
    CurrencyID: 112,
    ShortName: 'QIWI',
    Name: 'QIWI RUBLE',
  },
  {
    CurrencyID: 168,
    ShortName: 'TRX',
    Name: 'TRON crypto currency',
  },
  {
    CurrencyID: 169,
    ShortName: 'USDT',
    Name: 'USDT ERC 2.0 token',
  },
  {
    CurrencyID: 170,
    ShortName: 'ETH',
    Name: 'Ethereum ERC 2.0',
  },
]

export const AMOUNT_TO_WITHDRAW = [25, 50, 100, 'Custom']
