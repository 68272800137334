import { Api } from '@/5_shared/api'
import { combine, forward, guard, sample } from 'effector'

import { domain } from './domain'
import {
  login,
  loginFx,
  logout,
  signUp,
  signUpFx,
  signUpPhone,
  signUpPhoneFx,
  signUpPromo,
  signUpPromoFx,
  registrationRequest,
  codeVerifyFx,
  codeVerify,
  passwordResetFx,
  passwordReset,
  passwordResetVerifyFx,
  passwordResetVerify,
} from './events'
import { LocalStorageService } from '@/5_shared/local-storage'
import { NotificationService } from '@/7_services/notification-service'
import { getUserId } from '../utils'
import { setMainCurrency } from '@/2_widgets/dashboard-modal'
import i18next from '@/lang'
import { User } from '../types'

const $user = domain
  .createStore<User | null>(LocalStorageService.getItem('user'))
  .on(loginFx.doneData, (_, payload) => {
    return {
      ...payload,
      user_id: getUserId(payload.jwt),
    }
  })
  .on(logout, () => null)
  .on(setMainCurrency, (store, payload) => {
    const newUser = {
      ...store,
      currency_id: payload,
    }
    LocalStorageService.setItem('user', newUser)
    return newUser
  })

// const $isLoggedIn = $user.map((state) => Boolean(state))
const $isLoggedIn = domain
  .createStore(!!LocalStorageService.getItem('user'))
  .on(loginFx.doneData, () => true)
  .on(logout, () => {
    LocalStorageService.removeItem('user')
    return false
  })

const $isLoginPending = loginFx.pending
const $isSignUpPhonePending = signUpPhoneFx.pending
const $isSignUpPromoPending = signUpPromoFx.pending

guard({
  source: login,
  filter: loginFx.pending.map((pending) => !pending),
  target: loginFx,
})

guard({
  source: signUp,
  filter: signUpFx.pending.map((pending) => !pending),
  target: signUpFx,
})

guard({
  source: signUpPhone,
  filter: signUpPhoneFx.pending.map((pending) => !pending),
  target: signUpPhoneFx,
})

guard({
  source: signUpPromo,
  filter: signUpPromoFx.pending.map((pending) => !pending),
  target: signUpPromoFx,
})

forward({
  from: signUpPromoFx.done,
  to: registrationRequest,
})

guard({
  source: passwordReset,
  filter: passwordResetFx.pending.map((pending) => !pending),
  target: passwordResetFx,
})

guard({
  source: passwordResetVerify,
  filter: passwordResetVerifyFx.pending.map((pending) => !pending),
  target: passwordResetVerifyFx,
})

guard({
  source: codeVerify,
  filter: codeVerifyFx.pending.map((pending) => !pending),
  target: codeVerifyFx,
})

loginFx.doneData.watch((data) => {
  if (data?.success === 1) {
    Api.setToken(data.jwt)
    LocalStorageService.setItem('user', data)
    NotificationService.success(i18next.t('notifications.login.success'))
  }
})

signUpPhoneFx.doneData.watch(() => {
  NotificationService.success(i18next.t('notifications.registration.success'))
})

signUpPromoFx.doneData.watch(() => {
  NotificationService.success(i18next.t('notifications.registration.success'))
})

sample({
  source: signUpPhoneFx.done,
  fn: ({ params }) => ({
    login: params.user_login,
    password: params.user_password,
  }),
  target: loginFx,
})

codeVerifyFx.doneData.watch(() => {
  NotificationService.success(i18next.t('notifications.verification.success'))
})

codeVerifyFx.fail.watch(() => {
  NotificationService.success(i18next.t('notifications.verification.error'))
})

signUpPhoneFx.fail.watch((data) => {
  // try {
  //   NotificationService.error(
  //     `Registration error: ${JSON.stringify(data.error)}`
  //   )
  // } catch (e) {
  NotificationService.error(i18next.t('notifications.registration.error'))
  // }
})

signUpPromoFx.fail.watch((data) => {
  // try {
  //   NotificationService.error(
  //     `Registration error: ${JSON.stringify(data.error)}`
  //   )
  // } catch (e) {
  NotificationService.error(i18next.t('notifications.registration.error'))
  // }
})

loginFx.fail.watch((data) => {
  // try {
  //   NotificationService.error(`Login error: ${JSON.stringify(data.error)}`)
  // } catch (e) {
  NotificationService.error(i18next.t('notifications.login.error'))
  // }
})

logout.watch(() => {
  Api.clearToken()
  LocalStorageService.clear()
  // NotificationService.info(i18next.t('notifications.login.out'))
})

export const data = combine({
  user: $user,
  isLoggedIn: $isLoggedIn,
  isLoginPending: $isLoginPending,
  isSignUpPhonePending: $isSignUpPhonePending,
  isSignUpPromoPending: $isSignUpPromoPending,
})
