import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import * as Styled from './styled'
import { CurrencyCard } from '@/4_entities/currency/ui'
import { Button, ButtonType, ButtonVariant } from '@/5_shared/ui'
import { useStore } from 'effector-react'
import {
  $selectedWithdrawAmount,
  $withdrawDestinationWallet,
  $withdrawSelectedCurrency,
  setWithdrawAmount,
  setWithdrawCurrency,
  setWithdrawDestinationWallet,
  withdrawRequest,
} from '../../../model'
import { UserService } from '@/7_services/user'
import { AMOUNT_TO_WITHDRAW } from '@/2_widgets/dashboard-modal/data'
import {
  $fetchedBalancesList,
  $fetchedPaymentSystems,
} from '@/2_widgets/profile-block/model'
import { useTranslation } from 'react-i18next'
import { Currency } from '@/4_entities/currency'
import { NotificationService } from '@/7_services/notification-service'

function roundToTwo(num: number) {
  return Math.round((num + Number.EPSILON) * 100) / 100
}

const handleResetCurrency = () => {
  setWithdrawCurrency(null)
}

const handleSetWithdrawDestinationWallet = (
  event: ChangeEvent<HTMLInputElement>
) => {
  setWithdrawDestinationWallet(event.target.value)
}

const handleSetWithdrawCustomAmount = (
  event: ChangeEvent<HTMLInputElement>
) => {
  setWithdrawAmount(Number(event.target.value))
}

export const CurrencyStep: FC = memo(() => {
  const withdrawSelectedCurrency = useStore($withdrawSelectedCurrency)
  const withdrawDestinationWallet = useStore($withdrawDestinationWallet)
  const selectedWithdrawAmount = useStore($selectedWithdrawAmount)
  const { user } = useStore(UserService.getStore())
  const balances = useStore($fetchedBalancesList)
  const paymentSystems = useStore($fetchedPaymentSystems)
  const [balance, setBalance] = useState<number | undefined>(0)
  const { t } = useTranslation()
  useEffect(() => {
    setBalance(
      balances?.find((b) => b?.currency_id === withdrawSelectedCurrency)
        ?.balance
    )
  }, [balances, withdrawSelectedCurrency])

  const handleWithdrawRequest = () => {
    if (withdrawDestinationWallet.trim().length === 0) {
      NotificationService.error(t('notifications.wallet.emptyAddress'))
      return
    }
    const system = paymentSystems.find(
      (sys) => sys.system_currency === withdrawSelectedCurrency
    )
    const mul = balances?.find(
      (b) => b?.currency_id === withdrawSelectedCurrency
    )?.money_2_credit_mul
    withdrawRequest({
      payment_type: Number(system?.id),
      user_id: user?.user_id,
      wallet_number: withdrawDestinationWallet,
      sum: roundToTwo(selectedWithdrawAmount) * 100 * Number(mul),
    })
  }

  const handleSetWithdrawAmount = (amount: number | string) => {
    if (typeof amount === 'string') {
      setWithdrawAmount(0)
    } else {
      if (Number(balance) > 0) {
        setWithdrawAmount((Number(balance) * amount) / 100)
      }
    }
  }

  if (withdrawSelectedCurrency === null) {
    return null
  }

  return (
    <>
      <Styled.CurrencyMainWrap>
        <Styled.CurrencyStepHeadBlock>
          <Styled.CurrencyStepSelectCurrencyWrapper>
            <Styled.SelectCurrencyTitle>
              {t('withdrawTab.title')}
            </Styled.SelectCurrencyTitle>
            <Styled.SelectCurrencyText>
              {t('withdrawTab.label')}
            </Styled.SelectCurrencyText>
          </Styled.CurrencyStepSelectCurrencyWrapper>

          <Styled.CurrencyTabCurrencyCardWrapper>
            <CurrencyCard
              currency={user?.currency_list.find(
                (cur: Currency) => cur.CurrencyID === withdrawSelectedCurrency
              )}
            />
          </Styled.CurrencyTabCurrencyCardWrapper>
        </Styled.CurrencyStepHeadBlock>

        <Styled.Labeled label={t('topUpTab.walletNumber')}>
          <Styled.Input
            onChange={handleSetWithdrawDestinationWallet}
            value={withdrawDestinationWallet}
          />
        </Styled.Labeled>

        <Styled.Labeled label={t('withdrawTab.withdrawalAmount')}>
          <Styled.Input
            onChange={handleSetWithdrawCustomAmount}
            value={selectedWithdrawAmount}
            type="number"
            placeholder="0.00"
            step="0.01"
            min="0.00"
            pattern="\d+(\.\d{2})?"
            required
          />
        </Styled.Labeled>
      </Styled.CurrencyMainWrap>

      <Styled.Labeled>
        <Styled.AmountBlock>
          {AMOUNT_TO_WITHDRAW.map((amount) => (
            <Styled.Amount
              onClick={() => handleSetWithdrawAmount(amount)}
              $isSelected={selectedWithdrawAmount === amount}
              key={amount}
            >
              {typeof amount === 'string' ? amount : `${amount}%`}
            </Styled.Amount>
          ))}
        </Styled.AmountBlock>
      </Styled.Labeled>

      <Styled.ButtonsWrapper>
        <Button
          type={ButtonType.Neutral}
          variant={ButtonVariant.Inverted}
          fluid
          onClick={handleResetCurrency}
        >
          {t('buttons.cancel')}
        </Button>

        <Button fluid onClick={handleWithdrawRequest}>
          {t('buttons.withdraw')}
        </Button>
      </Styled.ButtonsWrapper>
    </>
  )
})

CurrencyStep.displayName = 'CurrencyStep'
