import { FC, useEffect, useRef } from 'react'
import * as Styled from './styled'
import { useStore } from 'effector-react'
import {
  $fetchedBalancesList,
  $isProfileBlockOpened,
  closeDropdown,
  dropdownClick,
  getBalances,
} from '../model'
import { useOutsideClick } from '@/5_shared/hooks'
import { openProfileModal, ProfileModalTab } from '@/2_widgets/dashboard-modal'
import { UserService } from '@/7_services/user'
import * as LanguageStyled from '@/3_features/language-selection/ui/styled'
import { IconName } from '@/5_shared/ui'
import { Balance } from '../types'
import { useTranslation } from 'react-i18next'
import { Currency } from '@/4_entities/currency'

const handleProfileClick = () => {
  openProfileModal()
}
const handleTopUpClick = () => {
  openProfileModal(ProfileModalTab.TopUp)
}
const handleWithdrawClick = () => {
  openProfileModal(ProfileModalTab.Withdraw)
}
const handleCreditsClick = () => {
  openProfileModal(ProfileModalTab.Credits)
}
const handleLogout = () => {
  UserService.logout()
}

const onDropdownClick = () => {
  dropdownClick()
}

export const ProfileBlock: FC = () => {
  const { t } = useTranslation()
  const isOpened = useStore($isProfileBlockOpened)
  const balances = useStore($fetchedBalancesList)
  const { user } = useStore(UserService.getStore())
  const thisRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    getBalances()
    const balancesTimer = setInterval(() => {
      getBalances()
    }, 10000)
    return () => {
      clearInterval(balancesTimer)
    }
  }, [])

  useOutsideClick(thisRef, isOpened, closeDropdown)

  return (
    <Styled.Container>
      <Styled.Root ref={thisRef} $isShown={isOpened}>
        <Styled.ImageWrapper onClick={onDropdownClick}>
          <Styled.ProfileAvatarIcon />
        </Styled.ImageWrapper>

        <Styled.ProfileInfo onClick={onDropdownClick}>
          <Styled.ProfileInfoBalance>
            {
              user?.currency_list.find(
                (curr: Currency) => user?.currency_id === curr.CurrencyID
              )?.ShortName
            }
            &nbsp;
            {
              balances?.find(
                (balance: Balance) => balance?.currency_id === user?.currency_id
              )?.balance
            }{' '}
            <Styled.ProfileTitle>{t('onBalance')}</Styled.ProfileTitle>
          </Styled.ProfileInfoBalance>
          <Styled.ProfileTitle>
            {t('betsWithdraw')}&nbsp;
            {
              balances?.find(
                (balance: Balance) => balance?.currency_id === user?.currency_id
              )?.spent
            }
          </Styled.ProfileTitle>
          <Styled.ProfileTitle>
            {user?.user_info?.login_text}
          </Styled.ProfileTitle>
        </Styled.ProfileInfo>

        <Styled.Dropdown $isShown={isOpened}>
          <Styled.Option $isSelected={false} onClick={handleProfileClick}>
            <Styled.DropdownIcon name={IconName.EditContained} /> {t('profile')}
          </Styled.Option>
          <Styled.Option $isSelected={false} onClick={handleCreditsClick}>
            <Styled.DropdownIcon name={IconName.Wallet03} /> {t('credits')}
          </Styled.Option>
          <Styled.Option $isSelected={false} onClick={handleLogout}>
            <Styled.DropdownIcon name={IconName.Logout01} /> {t('logout')}
          </Styled.Option>
        </Styled.Dropdown>

        <LanguageStyled.IconWrapper onClick={onDropdownClick}>
          {isOpened ? (
            <LanguageStyled.ChevronUpIcon />
          ) : (
            <LanguageStyled.ChevronDownIcon />
          )}
        </LanguageStyled.IconWrapper>
      </Styled.Root>
      <Styled.Root $isShown={false}>
        <Styled.Btn onClick={handleTopUpClick}>
          <Styled.DropdownIcon name={IconName.Plus01} />
          {t('topUp')}
        </Styled.Btn>
        <Styled.Btn onClick={handleWithdrawClick}>
          <Styled.DropdownIcon name={IconName.Download01} />
          {t('withdraw')}
        </Styled.Btn>
      </Styled.Root>
    </Styled.Container>
  )
}

ProfileBlock.displayName = 'ProfileBlock'
